import { ConfigurationStatusComponent } from "@/components/common";
import { getEntitlement, getEnvIsDisable, getSelectedOrg } from "@/store";
import {
  ENTITLEMENT_STATUS,
  ORG_PHASE,
  SUMMARY_LANDING_PAGE_TYPES,
  SUMMARY_LANDING_SCREEN_STATUS,
} from "@/utils/constants";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useRouter } from "next/navigation";
import React from "react";
import { useSelector } from "react-redux";

interface ReadyToStartProps {
  configurationProps: {
    title: string;
    leftBadgeLabel: string;
    rightBadgeLabel: string;
    headingText: string;
    paragraphText: string;
    link: string;
    justifyContent?: "center" | "flex-start";
  };
}

const ReadyToStart: React.FC<ReadyToStartProps> = ({ configurationProps }) => {
  const {
    title,
    leftBadgeLabel,
    rightBadgeLabel,
    headingText,
    paragraphText,
    link,
  } = configurationProps;
  const router = useRouter();
  const entitlement = useSelector(getEntitlement);
  const currOrg = useSelector(getSelectedOrg);

  let entitlementType: "gl" | "ic" | "tc" | "crt" = "ic";
  switch (title) {
    case SUMMARY_LANDING_PAGE_TYPES.gl:
      entitlementType = "gl";
      break;
    case SUMMARY_LANDING_PAGE_TYPES.ic:
      entitlementType = "ic";
      break;
    case SUMMARY_LANDING_PAGE_TYPES.crt:
      entitlementType = "crt";
      break;
    case "Transaction Code Setup":
      entitlementType = "tc";
      break;
  }
  const isDisabled = useSelector(getEnvIsDisable);

  return (
    <>
      <ConfigurationStatusComponent
        title={title}
        status={SUMMARY_LANDING_SCREEN_STATUS.not_started}
        leftBadgeLabel={leftBadgeLabel}
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.completed}
        rightBadgeLabel={rightBadgeLabel}
        rightBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.not_started}
      />
      <Flex
        direction={"column"}
        justifyContent={"space-between"}
        align={"center"}
        className="text-centered"
      >
        {currOrg.phase === ORG_PHASE.implementation && (
          <>
            <Text className="heading-text" as="h3">
              {headingText}
            </Text>
            <div className="paragraph-text">
              <Text className="paragraph-heading">WHAT TO EXPECT</Text>
              <Text className="paragraph">{paragraphText}</Text>
            </div>
          </>
        )}
        <Button
          w="238px"
          className="app-btn-inverse-secondary-get-started"
          onClick={() => router.push(link)}
          isDisabled={
            isDisabled ||
            entitlement?.[entitlementType] === ENTITLEMENT_STATUS.noAccess ||
            entitlement?.[entitlementType] === ENTITLEMENT_STATUS.readOnly
          }
        >
          Get started
        </Button>
      </Flex>
    </>
  );
};

export default ReadyToStart;
