import { ConfigurationStatusComponent } from "@/components/common";
import { Flex, Text } from "@chakra-ui/react";
import { lockImage } from "public/assets";
import React from "react";

import "./UnmetPreRequisites.scss";
import { ORG_PHASE, SUMMARY_LANDING_SCREEN_STATUS } from "@/utils/constants";
import { useSelector } from "react-redux";
import { getSelectedOrg } from "@/store";

interface UnmetPreRequisitesProps {
  configurationProps: {
    title: string;
    leftBadgeLabel: string;
    rightBadgeLabel: string;
    headingText: string;
    paragraphText: string;
  };
}

const UnmetPreRequisites: React.FC<UnmetPreRequisitesProps> = ({
  configurationProps,
}) => {
  const { title, leftBadgeLabel, rightBadgeLabel, headingText, paragraphText } =
    configurationProps;
  const currOrg = useSelector(getSelectedOrg);

  return (
    <>
      <ConfigurationStatusComponent
        title={title}
        status={SUMMARY_LANDING_SCREEN_STATUS.unmet}
        leftBadgeLabel={leftBadgeLabel}
        leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.in_progress}
        rightBadgeLabel={rightBadgeLabel}
        rightBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.not_started}
      />
      {currOrg.phase === ORG_PHASE.implementation && (
        <Flex
          direction={"column"}
          justifyContent={"space-between"}
          align={"center"}
          className="text-centered not-completed"
        >
          <Text className="heading-text" as="h3">
            {headingText}
          </Text>
          <div className="paragraph-text">
            <Text className="paragraph-heading">WHAT TO EXPECT</Text>
            <Text className="paragraph">{paragraphText}</Text>
          </div>
        </Flex>
      )}
    </>
  );
};

export default UnmetPreRequisites;
