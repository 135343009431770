import { CRT_ENTITY, GL_ENTITY, IC_ENTITY } from "@/utils/constants";

export type ListConfigType = {
  pk: string;
  nameKey: string;
  title: string;
  subTitle: string;
  showAddBtn: boolean;
  disableBtn: boolean;
  columns: {
    header: string;
    accessorKey: string;
    enableSorting?: boolean;
  }[];
};

export const IC_LIST_CONFIG = {
  [IC_ENTITY.account_group]: {
    pk: "acctGroup",
    nameKey: "acctGroup",
    title: "Establish account group",
    subTitle:
      "Segment accounts for streamlined policy implementation and management",
    showAddBtn: true,
    columns: [
      {
        header: "Group",
        accessorKey: "acctGroup",
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "desc",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [IC_ENTITY.asset_catalog]: {
    // using delimiter
    pk: "assetClass~assetId",
    nameKey: "assetId",
    title: "Configure the asset catalog",
    subTitle:
      "Define asset classes and identifiers to establish transactional currencies and maintain regulatory compliance",
    showAddBtn: true,
    columns: [
      {
        header: "Asset Identifier",
        accessorKey: "assetId",
        enableSorting: true,
      },
      {
        header: "Asset Class",
        accessorKey: "assetClass",
        enableSorting: true,
      },
      {
        header: "Asset Description",
        accessorKey: "desc",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [IC_ENTITY.account_type]: {
    pk: "acctType",
    nameKey: "acctType",
    title: "Establish account types",
    subTitle:
      "Customize account types to enhance product offerings and ensure compliance",
    showAddBtn: true,
    columns: [
      {
        header: "Type",
        accessorKey: "acctType",
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "desc",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [IC_ENTITY.financial_calendar]: {
    pk: "calendarName",
    nameKey: "calendarName",
    title: "Set up financial calendars",
    subTitle:
      " Align events with operational and fiscal cycles for compliance and efficiency",
    showAddBtn: true,
    columns: [
      {
        header: "Name",
        accessorKey: "calendarName",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [IC_ENTITY.financial_organization]: {
    pk: "_Id",
    nameKey: "name",
    title: "Configure your financial institution",
    subTitle:
      "Define key attributes for your institution for smooth operations and regulatory compliance",
    showAddBtn: true,
    columns: [
      {
        header: "Name",
        accessorKey: "name",
        enableSorting: true,
      },
      {
        header: "ID",
        accessorKey: "_Id",
        enableSorting: true,
      },
      {
        header: "Financial Institution ID",
        accessorKey: "finInstAba",
        enableSorting: true,
      },
      // TODO: find where data is coming from?
      { header: "Relationship", accessorKey: "relation", enableSorting: true },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [IC_ENTITY.bank_parameters]: {
    pk: "_Id",
    // Bank param name = fin org name
    nameKey: `${IC_ENTITY.financial_organization}.name`,
    title: "Configure financial institution (bank) parameters",
    subTitle:
      "Configure operational parameters to meet institutional needs and goals",
    showAddBtn: false,
    columns: [
      {
        header: "Institution Name",
        accessorKey: "name",
        enableSorting: true,
      },
      {
        header: "ID",
        accessorKey: "_Id",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
};
export const GL_LIST_CONFIG = {
  [GL_ENTITY.accounting_segments]: {
    pk: "acctgSeg",
    nameKey: "acctgSeg",
    title: "Accounting Segments",
    subTitle:
      "Hierarchical structure of your General Ledger and enhance reporting",
    showAddBtn: true,
    columns: [
      {
        header: "Name",
        accessorKey: "acctgSeg",
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "desc",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [GL_ENTITY.accounts]: {
    // using delimiter
    pk: "acctGroup~acctNbr",
    nameKey: "acctNbr",
    title: "Accounts",
    subTitle:
      "Institution’s accounting process, specifying the category for each account",
    showAddBtn: true,
    columns: [
      {
        header: "Number",
        accessorKey: "acctNbr",
        enableSorting: true,
      },
      {
        header: "Account Group",
        accessorKey: "acctGroup",
        enableSorting: true,
      },
      {
        header: "Title",
        accessorKey: "acctTitle",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [GL_ENTITY.system_accounts]: {
    pk: "acctName",
    nameKey: "acctName",
    title: "System Accounts",
    subTitle:
      "Placeholder accounts when exceptions occur until the transaction settles",
    showAddBtn: true,
    columns: [
      {
        header: "Name",
        accessorKey: "acctName",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [GL_ENTITY.sets]: {
    pk: "glSetCode",
    nameKey: "glSetCode",
    title: "Sets",
    subTitle:
      "Control the transactional and balance-related activities of a particular position",
    showAddBtn: true,
    columns: [
      {
        header: "Code",
        accessorKey: "glSetCode",
        enableSorting: true,
      },
      {
        header: "Description",
        accessorKey: "desc",
        enableSorting: true,
      },
      {
        header: "",
        accessorKey: "action",
        enableSorting: false,
      },
    ],
  },
  [GL_ENTITY.positions]: {
    pk: "_Id",
    nameKey: "name",
    title: "Positions",
    subTitle:
      "Account for activity at a more granular level, nestled below related accounts ",
    showAddBtn: false,
    columns: [
      { header: "Name", accessorKey: "posnName", enableSorting: true },
      {
        header: "Account group",
        accessorKey: "acctGroup",
        enableSorting: true,
      },
      {
        header: "Account number",
        accessorKey: "acctNbr",
        enableSorting: true,
      },
      {
        header: "Position account number",
        accessorKey: "posnAcctNbr",
        enableSorting: true,
      },
      { header: "", accessorKey: "", enableSorting: false, id:1 },
    ],
  },
};
export const CRT_LIST_CONFIG = {
  [CRT_ENTITY.general_setup]: {
    pk: "custRelType",
    nameKey: "custRelType",
    title: "General setup",
    subTitle:
      "Hierarchical structure of your General Ledger and enhance reporting",
    showAddBtn: true,
    columns: [],
  },
  [CRT_ENTITY.party_relationships]: {
    // using delimiter
    pk: "custRelType",
    nameKey: "custRelType",
    title: "Party relationships",
    subTitle:
      "Institution’s accounting process, specifying the category for each account",
    showAddBtn: true,
    columns: [],
  },
};
