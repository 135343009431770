import { deepCopy, useEntityId } from "@/utils/common";
import { ProductWorkflowModelType } from "../product-management";
import { AccountTypeData } from "./model";
import { getItemFromStorage, setItemInStorage, STORAGE_KEY } from "@/utils/storage";
import { NEW_IC_ENTITY_ID } from "@/utils/constants";

function setIcCopyFlagInStorage(flag: boolean) {
  setItemInStorage(STORAGE_KEY["IC_COPY_FLAG"], flag);
}

function getIcCopyFlagInStorage(): boolean {
  return getItemFromStorage(STORAGE_KEY["IC_COPY_FLAG"]);
}

function checkIfICWorflowNeedsToBeCreated(
  entityId: any,
  workFlowData: ProductWorkflowModelType[],
  stageKey: string
) {
  if (entityId && entityId !== NEW_IC_ENTITY_ID) {
    const index = workFlowData.findIndex(
      (workFlow) =>
        workFlow.modelKey === entityId && workFlow.stage === stageKey
    );
    return index > -1 ? false : true;
  }
  return false;
}

function constructRelData(
  data: AccountTypeData | null,
  payload: any,
  currEntriesIndex: number
) {
  let relData = data?.rels?.length ? deepCopy(data.rels) : [];
  if (relData?.length) {
    relData[currEntriesIndex] = payload;
  } else {
    relData = [payload];
  }
  return relData;
}

const useAssetCatalogIds = () => {
  const assetCatId = useEntityId();
  return assetCatId?.split("~");
};

export {
  setIcCopyFlagInStorage,
  getIcCopyFlagInStorage,
  checkIfICWorflowNeedsToBeCreated,
  constructRelData,
  useAssetCatalogIds,
};
